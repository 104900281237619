import { useLocation } from "@reach/router"
import { Link, useTranslation } from "gatsby-plugin-react-i18next"
import * as React from "react"
import { Button, Col, Container, Row } from "react-bootstrap"
import ServiceNavigation from "../services/servicenavigation"
import "./ourproducts.scss"

const OurProducts = ({ className }) => {
  const [fullscreen, setFullscreen] = React.useState({
    AISecurity: true,
    Err0: true,
  })
  const [show, setShow] = React.useState({ AISecurity: false, Err0: false })
  const location = useLocation()
  const { t } = useTranslation()
  function handleShow(breakpoint, modal) {
    switch (modal) {
      case "AISecurity":
        setFullscreen(breakpoint)
        setShow({ AISecurity: true, Err0: false })
        break
      case "Err0":
        setFullscreen(breakpoint)
        setShow({ AISecurity: false, Err0: true })
        break
    }
  }

  return (
    <>
      <section
        id="products"
        className={(className ? className + " " : "") + "products"}
      >
        <Container fluid>
          <div className="first-products-row">
            <div className="max-container">
              <ServiceNavigation></ServiceNavigation>

              <Row className="parent-div icon-left">
                <Col xs={12} sm={12} md={3} lg={3} xl={3}>
                  <div className="icon-container icon-1"></div>
                </Col>
                <Col
                  xs={12}
                  sm={12}
                  md={9}
                  lg={9}
                  xl={9}
                  className="text-container"
                >
                  <h2>{t("AI Security Lab")}</h2>
                  <p>
                    {t(
                      "Created to address growing Al/ML risks and security concerns of Blue Trail Software's customers"
                    )}{" "}
                    {t(
                      "Combines cross functional teams from Blue Trail Software"
                    )}
                    {t(
                      "Maintains expertise, skills, best practice guidelines and ML security related research projects"
                    )}
                  </p>
                  <Button
                    key="1"
                    variant="none"
                    className="AISecurity-button link-grey-btn"
                    as={Link}
                    state={{ from: location.pathname }}
                    to="/caseaisecurity/"
                  >
                    {t("Know More")}
                  </Button>
                </Col>
              </Row>

              <Row className="parent-div icon-right">
                <Col
                  xs={12}
                  sm={12}
                  md={9}
                  lg={9}
                  xl={9}
                  className="text-container"
                >
                  <h2>Err0.io</h2>
                  <p>
                    {" "}
                    {t("Err0 is a unique software error management solution")}
                  </p>
                  <a
                    className="link-grey-btn"
                    href="http://www.err0.io"
                    target="_blank"
                    rel="noreferrer"
                  >
                    {t("Know More")}
                  </a>
                </Col>
                <Col xs={12} sm={12} md={3} lg={3} xl={3}>
                  <div className="icon-container icon-2"></div>
                </Col>
              </Row>

              <Row className="parent-div icon-left">
                <Col xs={12} sm={12} md={3} lg={3} xl={3}>
                  <div className="icon-container icon-3"></div>
                </Col>
                <Col
                  xs={12}
                  sm={12}
                  md={9}
                  lg={9}
                  xl={9}
                  className="text-container"
                >
                  <h2>Luna</h2>
                  <p>{t("Helping women go through their menopause")}</p>
                  <p>
                    {t(
                      "Every persons body differs and our daily life patterns can affect the intensity and the length of hot flashes"
                    )}
                    <br></br>
                    {t(
                      "My luna helps women monitor these changes to keep them under control by tracking menopausal symptoms"
                    )}
                    <br></br>
                    {t("Our features aim to improve your quality of life.")}
                    <br></br>
                    <br></br>
                    {t(
                      "The application has been used for various research projects"
                    )}{" "}
                  </p>
                  <a
                    href="https://www.pcrm.org/clinical-research/fighting-hot-flashes-with-diet"
                    target="_blank"
                    rel="noreferrer"
                    className="link-quote"
                  >
                    {t("Fighting hot flashes with diet")}
                  </a>
                </Col>
              </Row>
            </div>
          </div>
        </Container>
      </section>
    </>
  )
}

export default OurProducts
